import React from "react";
import {CircularProgress} from "@mui/material";

import "./LoadingCircle.scss";

class LoadingCircle extends React.Component {
    render() {
        return (
            <div {...this.props} className={`LoadingCircle ${this.props.className}`}>
                <div className="container">
                    <CircularProgress className='circle' size={this.props.size ?? 40} thickness={5} color="primary" />
                </div>
            </div>);
    }
}

export default LoadingCircle;