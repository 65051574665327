import { connect } from 'react-redux';

import Organization from './Organization';
import OrganizationEntity from '../../redux/modules/entities/organizations/Organization';
import { redirectToRelativeUrlSuccess, switchOrganization } from '../../redux/modules/entities/organizations/actions';
import { canManageDevices, hasPlugins, hasRole, isFetchingUserInfo, getAvailableEventTypes } from '../../redux/modules/session/selectors';

const mapStateToProps = (state) => {
    return {
        isFetchingUser: isFetchingUserInfo(state),
        isSwitchingOrganization: OrganizationEntity.isSwitchingOrganization(state),
        errorSwitchingOrganization: OrganizationEntity.errorSwitchingOrganization(state),
        currentOrganization: OrganizationEntity.getCurrentOrganization(state),
        isLoading: OrganizationEntity.isFetchingCurrentOrganization(state) || OrganizationEntity.isFetchingRootOrganizations(state),
        hasPlugins: hasPlugins(state),
        canManageDevices: canManageDevices(state),
        redirectToUrl: OrganizationEntity.getRedirectToAbsoluteUrl(state),
        orgHasFeature: OrganizationEntity.getFeatureAccesor(state),
        hasRoleCanary: hasRole(state, 'ROLE_CANARY'),
        userHasOrgPermission: OrganizationEntity.getPermissionAccesor(state),
        firstRootOrgId: OrganizationEntity.getFirstRootOrganizationId(state),
        availableEventTypes: getAvailableEventTypes(state)
    };
};

const mapDispatchToProps = (dispatch) => ({
    switchOrganization (organizationPath) {
        dispatch(switchOrganization(organizationPath));
    },

    redirectToRelativeUrlSuccess () {
        dispatch(redirectToRelativeUrlSuccess());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
