const NAMESPACE = 'session/';

export const CLOSE_ORG_TREE_MODAL = `${NAMESPACE}CLOSE_ORG_TREE_MODAL`;

export function closeOrgTreeModal() {
    return {
        type: CLOSE_ORG_TREE_MODAL,
        payload: {},
    };
}

export const OPEN_ORG_TREE_MODAL = `${NAMESPACE}OPEN_ORG_TREE_MODAL`;

export function openOrgTreeModal() {
    return {
        type: OPEN_ORG_TREE_MODAL,
        payload: {},
    };
}

export const GET_USER_INFO = `${NAMESPACE}GET_USER_INFO`;

export function getUserInfo() {
    return {
        type: GET_USER_INFO,
        payload: { },
    };
}


export const GET_USER_INFO_SUCCESS = `${NAMESPACE}GET_USER_INFO_SUCCESS`;

export function getUserInfoSuccess(userInfo) {
    return {
        type: GET_USER_INFO_SUCCESS,
        payload: {
            userInfo,
        },
    };
}


export const GET_USER_INFO_FAILURE = `${NAMESPACE}GET_USER_INFO_FAILURE`;

export function getUserInfoFailure(error) {
    return {
        type: GET_USER_INFO_FAILURE,
        payload: {
            error,
        },
    };
}


export const SET_MESSAGE = `${NAMESPACE}SET_MESSAGE`;

export function setMessage(key, value) {
    return {
        type: SET_MESSAGE,
        payload: { key, value },
    };
}

export const SET_USER_SETTINGS = `${NAMESPACE}SET_USER_SETTINGS`;

export function setUserSettings(key, value) {
    return {
        type: SET_USER_SETTINGS,
        payload: { key, value },
    };
}


export const SET_USER_SETTINGS_SUCCESS = `${NAMESPACE}SET_USER_SETTINGS_SUCCESS`;

export function setUserSettingsSuccess() {
    return {
        type: SET_USER_SETTINGS_SUCCESS,
        payload: { },
    };
}


export const SET_USER_SETTINGS_FAILURE = `${NAMESPACE}SET_USER_SETTINGS_FAILURE`;

export function setUserSettingsFailure(error) {
    return {
        type: SET_USER_SETTINGS_FAILURE,
        payload: {
            error,
        },
    };
}

export const GET_USER_SETTINGS = `${NAMESPACE}GET_USER_SETTINGS`;

export function getUserSettings() {
    return {
        type: GET_USER_SETTINGS,
        payload: { },
    };
}


export const GET_USER_SETTINGS_SUCCESS = `${NAMESPACE}GET_USER_SETTINGS_SUCCESS`;

export function getUserSettingsSuccess(userSettings) {
    return {
        type: GET_USER_SETTINGS_SUCCESS,
        payload: {
            userSettings,
        },
    };
}


export const GET_USER_SETTINGS_FAILURE = `${NAMESPACE}GET_USER_SETTINGS_FAILURE`;

export function getUserSettingsFailure(error) {
    return {
        type: GET_USER_SETTINGS_FAILURE,
        payload: {
            error,
        },
    };
}

export const BOOKMARK_ORG = `${NAMESPACE}BOOKMARK_ORG`;

export function bookmarkOrg(org_id, isBookmarked) {
    return {
        type: BOOKMARK_ORG,
        payload: { org_id, isBookmarked },
    };
}


export const BOOKMARK_ORG_SUCCESS = `${NAMESPACE}BOOKMARK_ORG_SUCCESS`;

export function bookmarkOrgSuccess(bookmarks) {
    return {
        type: BOOKMARK_ORG_SUCCESS,
        payload: {
            bookmarks,
        },
    };
}


export const BOOKMARK_ORG_FAILURE = `${NAMESPACE}BOOKMARK_ORG_FAILURE`;

export function bookmarkOrgFailure(error) {
    return {
        type: BOOKMARK_ORG_FAILURE,
        payload: {
            error,
        },
    };
}

export const SET_CANVA_INSTANCE = `${NAMESPACE}SET_CANVA_INSTANCE`;

export function setCanvaInstance(canvaInstance) {
    return {
        type: SET_CANVA_INSTANCE,
        payload: {
            canvaInstance,
        },
    }
}

export const CHECK_AVAILABLE_EVENT_TYPES_SUCCESS = `${NAMESPACE}CHECK_AVAILABLE_EVENT_TYPES_SUCCESS`;

export function checkAvailableEventTypesSuccess(availableEventTypes) {
    return {
        type: CHECK_AVAILABLE_EVENT_TYPES_SUCCESS,
        payload: {
            availableEventTypes,
        },
    };
}

export const CHECK_AVAILABLE_EVENT_TYPES_FAILURE = `${NAMESPACE}CHECK_AVAILABLE_EVENT_TYPES_FAILURE`;

export function checkAvailableEventTypesFailure(error) {
    return {
        type: CHECK_AVAILABLE_EVENT_TYPES_FAILURE,
        payload: {
            error,
        },
    };
}