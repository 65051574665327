import React from 'react';

import AllFilteredIcon from '../../assets/ES_AllFiltered.svg';
import EmptyBoxIcon from '../../assets/ES_EmptyBox.svg';
import EmptySocialIcon from '../../assets/ES_Social.svg';
import EmptyTagsIcon from '../../assets/Empty_Tags_Icon.svg';
import ErrorIcon from '../../assets/ES_ErrorIcon.svg';
import LaunchIcon from '@mui/icons-material/Launch';
import NoItemsIcon from '../../assets/ES_NoItems.svg';
import NoEmployees from '../../assets/ES_NoEmployees.svg';
import NoEntChannels from '../../assets/ES_NoEntChannels.svg';
import NoResultsIcon from '../../assets/ES_NoResults.svg';
import NoDMAIcon from '../../assets/NoDMAIcon.svg';

import DevicesManagerGeneralIcon from '../../assets/DevicesManager/EmptyStates/General.svg';
import DevicesManagerOfflineIcon from '../../assets/DevicesManager/EmptyStates/OfflineDevices.svg';
import DevicesManagerOnlineIcon from '../../assets/DevicesManager/EmptyStates/OnlineDevices.svg';
import DevicesManagerPendingInstallationIcon from '../../assets/DevicesManager/EmptyStates/PendingIstallation.svg';
import DevicesManagerSecureLinksIcon from '../../assets/DevicesManager/EmptyStates/SecureLinks.svg';

import packageInfo from '../../../package.json';
const { version } = packageInfo;

import './EmptyState.scss';
import { Button } from '@mui/material';

const defaultErrorAction = (
    <Button
        key='default-error-button'
        variant="contained"
        color="primary"
        className="button err-boundary-reload-btn"
        onClick={() => window.location.reload()}>
        Try Again
    </Button>
);

const EMPTY_STATE_TYPE = {
    EMPTY_LIST: {
        icon: NoItemsIcon,
        title: "You don't have any elements!",
        message: "",
        actions: []
    },
    NO_RESULTS: {
        icon: NoResultsIcon,
        title: "No results matching",
        message: "Please check spelling or try searching with another word.",
        actions: []
    },
    NO_FILTERED_RESULTS: {
        icon: AllFilteredIcon,
        title: "No results were found",
        message: "Try adjusting your filter to find what you are looking for.",
        actions: []
    },
    ERROR: {
        icon: ErrorIcon,
        title: "Something went wrong",
        message: "Try reloading or return to homepage.",
        actions: [defaultErrorAction]
    },
    NO_UPDATES: {
        icon: EmptyBoxIcon,
        actions: []
    },
    NO_ITEM_SELECTED: {
        icon: null,
        title: "No item selected",
        message: "Select an item from the list and it will show here.",
        actions: []
    },
    NO_EMPLOYEES: {
        icon: NoEmployees,
        title: "Nothing here yet",
        message: "No employees to show",
        actions: []
    },
    NO_COMMENTS: {
        icon: NoEmployees,
        title: "Nothing here yet",
        message: "No comments to show",
        actions: []
    },
    NO_ENTERTAINMENT_CHANNELS: {
        icon: NoEntChannels,
        title: "No channels or games match this category.",
        message: "Try a different category or use the search box to continue discovering content.",
        actions: []
    },
    NO_LABELS: {
        icon: EmptyTagsIcon,
        title: "No Labels in this Organization.",
        actions: []
    },
    NO_SOCIAL_SOURCES: {
        icon: EmptySocialIcon,
        title: "You don’t have Direct Share enabled yet!",
        message: "Activate Direct Share to start receiving user generated content.",
        actions: []
    },
    NO_SOCIAL_CONTENT: {
        icon: EmptyBoxIcon,
        title: "You don’t have user generated content yet!",
        message: "A call to action encouraging patrons to post will appear on your screen until you start receiving photos.",
        actions: []
    },
    NO_CAMPAIGNS: {
        icon: EmptyBoxIcon,
        title: 'You don\'t have any QR Campaigns!',
        message: 'Create your first QR Campaign to collect and track performance of your TV or print assets.',
        actions: [],
    },
    DEVICES_MANAGER_GENERAL: {
        icon: DevicesManagerGeneralIcon,
        title: 'Add a Device',
        message: 'Start by adding a device! Once activated, it will appear here ready for easy management.',
        help: {
            enabled: true,
            url: "https://help.upshow.tv/hc/en-us/sections/360009377134-Devices-Manager"
        }
    },
    DEVICES_MANAGER_OFFLINE: {
        icon: DevicesManagerOfflineIcon,
        title: 'All Devices are Online',
        message: 'All your devices are online and ready to manage.'
    },
    DEVICES_MANAGER_ONLINE: {
        icon: DevicesManagerOnlineIcon,
        title: 'Get Your Devices Online',
        message: 'To have an active device it must be on and connected to the internet. Your devices will appear here once they are online.',
        help: {
            enabled: true,
            url: "https://help.upshow.tv/hc/en-us/sections/360009377134-Devices-Manager"
        }
    },
    DEVICES_MANAGER_PENDING_INSTALLATION: {
        icon: DevicesManagerPendingInstallationIcon,
        title: 'All Devices are Installed',
        message: 'Everything is set up and ready to go!'
    },
    DEVICES_MANAGER_SECURE_LINKS: {
        icon: DevicesManagerSecureLinksIcon,
        title: 'No Secure Links Created',
        message: 'Create a new Secure Link to view your screen experience. Once activated, it will appear here for easy management and monitoring.',
        help: {
            enabled: true,
            url: "https://help.upshow.tv/hc/en-us/sections/360009377134-Devices-Manager"
        }
    },
    MID_ORG_NO_DMA: {
        icon: NoDMAIcon,
        title: 'No DMA Set',
        message: <>No DMA set for any of your locations please reach out to <a href="mailto:support@everpass.com">support@everpass.com</a> to get properly configured.</>,
    },
    LEAF_ORG_NO_DMA: {
        icon: NoDMAIcon,
        title: 'No DMA Set',
        message: <>Your organization does not have a DMA set. Please reach out to <a href="mailto:support@everpass.com">support@everpass.com</a> to get properly configured.</>,
    },
}

const EmptyState = props => {
    const { type = 'EMPTY_LIST', icon, title, message, actions, height = "100%", width = "100%" } = props;

    const EMPTY_STATE = EMPTY_STATE_TYPE[type] ?? EMPTY_STATE_TYPE.EMPTY_LIST;

    return (
        <div className={`EmptyState ${type.toLowerCase()}`} style={{ height, width }}>
            {(!!icon || !!EMPTY_STATE.icon) &&
                <img src={icon || EMPTY_STATE.icon} alt='Icon' />}
            <h1 className='title'>{title || EMPTY_STATE.title}</h1>
            <div className='message'>{message || EMPTY_STATE.message}</div>
            <div className='buttons-container'>
                {!!actions ? actions : EMPTY_STATE.actions}
            </div>
            { EMPTY_STATE.help?.enabled &&
                <div className="help-guides">
                    Need more help?
                    <a href={EMPTY_STATE.help.url} target="_blank">
                        Check our guide <LaunchIcon />
                    </a>
                </div>
            }

            {type === 'ERROR' && <div className='version'>v{version}</div>}
        </div>
    );
}

export default EmptyState;
