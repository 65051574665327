import React, { useState } from 'react';
import {
    Badge,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import FilterIcon from '@mui/icons-material/Filter';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import TimelineIcon from '@mui/icons-material/Timeline';
import LinkedCameraOutlinedIcon from '@mui/icons-material/LinkedCameraOutlined';

import './EverpassSideBar.scss';

const iconColor = { root: 'iconColor' };
const iconTextOpen = { primary: 'iconText' };
const iconTextClosed = { primary: 'iconTextClosed' };
const disabledClass = { disabled: 'disabled' };

const MenuItem = ({
    open = false,
    invisible = true,
    Icon,
    text,
    to = '',
    title,
    disabled = false,
    component = NavLink,
    target, href,
    isActive = undefined
}) => {
    const sx = {
        '& .MuiBadge-badge': {
            backgroundColor: '#DE425B'
        }
    }
    return (
        <ListItem
            button
            target={target}
            href={href}
            className={`menu-item ${!open ? 'textBelow' : 'item-open'}`}
            component={component}
            title={title ?? text}
            to={to}
            disabled={disabled}
            classes={disabledClass}
            isActive={isActive}
        >
            <ListItemIcon className={`list-item-icon ${open ? 'open' : ''}`}>
                <Box>
                    <Badge sx={sx} invisible={invisible} overlap="circular" color="primary" variant="dot" anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                        <Icon size={24} classes={iconColor} />
                    </Badge>
                </Box>
            </ListItemIcon>
            <ListItemText classes={open ? iconTextOpen : iconTextClosed}> <span>{text}</span></ListItemText>
        </ListItem>
    );
}

const EverpassSideBar = ({ baseUrl, hasAccessTypeform, orgHasFeature, userHasOrgPermission, canManageDevices }) => {
    const [open, setOpen] = useState(true);
    const orgHas = typeof orgHasFeature === 'function' ? orgHasFeature : () => false;
    const userHas = typeof userHasOrgPermission === 'function' ? userHasOrgPermission : () => false;
    const HeaderMenuIcon = open ? MenuOpenIcon : MenuIcon;

    return (
        <Drawer
            variant="permanent"
            classes={{ docked: `EverpassSideBar ${!open ? 'EverpassSideBarClose' : ''}`, paper: `EverpassDrawerPaper ${!open ? 'EverpassDrawerPaperClose' : ''}` }}
            open={open}
            transitionDuration={{ enter: 1000, exit: 1000 }}
        >
            <div className={`DrawerHeader ${open ? 'toggle-left' : ''}`}>
                <IconButton className="header-toggle-btn " onClick={() => setOpen(!open)}>
                    <HeaderMenuIcon className="header-toggle-icon" />
                </IconButton>
            </div>
            <div className="DrawerContentContainer">
                <div className="DrawerContent">
                    <List className={`DrawerList ${open ? '' : 'addGap'}`}>

                        {open && ((userHas('spotlights') && orgHas('SPOTLIGHT')) || (orgHas('SPOTLIGHT')) || (userHas('social') && orgHas('SOCIAL')) || (userHas('campaigns') && orgHas('SPOTLIGHT') && orgHas('CAMPAIGNS') && hasAccessTypeform)) &&
                            <ListSubheader className="subHeader" disableSticky={true}>CONTENT</ListSubheader>
                        }
                        {userHas('spotlights') && orgHas('SPOTLIGHT') &&
                            <MenuItem open={open} Icon={CampaignOutlinedIcon} text="Promotions" title="Promotions" to={`${baseUrl}/promotions`} />
                        }
                        {orgHas('SPOTLIGHT') &&
                            <MenuItem open={open} Icon={FilterIcon} text="Library" title="Library" to={`${baseUrl}/library`} />
                        }
                        {userHas('social') && orgHas('SOCIAL') &&
                            <MenuItem open={open} Icon={LinkedCameraOutlinedIcon} text={open ? 'Direct Share' : 'Direct'} title={open ? 'Direct Share' : 'Direct'} to={`${baseUrl}/content`} />
                        }
                        {userHas('campaigns') && orgHas('SPOTLIGHT') && orgHas('CAMPAIGNS') && hasAccessTypeform &&
                            <MenuItem open={open} Icon={AssessmentOutlinedIcon} text="Surveys" to={`${baseUrl}/surveys`} />
                        }
                        <Divider color="#555555" className='divider' />
                        {open && ((userHas('playlists') && orgHas('MIXES')) || userHas('audio')) && (
                            <ListSubheader className="subHeader" disableSticky={true}>PROGRAMMING</ListSubheader>
                        )}
                        {userHas('playlists') && orgHas('MIXES') &&
                            <MenuItem open={open} Icon={PlaylistPlayIcon} text="Playlist" title="Playlist"
                                to={orgHas('SMART_PLAYLISTS') && window.location.pathname !== `${baseUrl}/playlists`
                                    ? `${baseUrl}/smart_playlists`
                                    : `${baseUrl}/playlists`} />}
                        {userHas('audio') &&
                            <MenuItem open={open} Icon={QueueMusicIcon} text="Audio" to={`${baseUrl}/audio`} />
                        }
                        <Divider color="#555555" className='divider' />
                        {open && ((userHas('devices') && canManageDevices && orgHas('DEVICES')) || (userHas('insights') && orgHas('SPOTLIGHT'))) &&
                            <ListSubheader className="subHeader" disableSticky={true}>REPORT</ListSubheader>
                        }
                        {userHas('insights') && orgHas('SPOTLIGHT') &&
                            <MenuItem open={open} Icon={TimelineIcon} text="Insights" to={`${baseUrl}/new_insights`} />
                        }
                    </List>
                </div>
            </div>
        </Drawer>
    );
}

export default EverpassSideBar