import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import _isEqual from 'lodash/isEqual';
import _find from 'lodash/find';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import { Link } from "react-router-dom";

import "./OrganizationTree.scss";
import { stringify } from "../../helpers/organizationPath";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';

class OrganizationTree extends React.Component {

    constructor(props) {
        super(props);

        this.getListItems = this.getListItems.bind(this);
        this.getList = this.getList.bind(this);
        this.openAndClose = this.openAndClose.bind(this);
        this.changeExpanded = this.changeExpanded.bind(this);
        this.parseOrgs = this.parseOrgs.bind(this);

        const { rootOrganizations } = this.props;

        this.state = {
            orgs: this.parseOrgs(rootOrganizations || [], [])
         };
    }

    changeExpanded(orgs, orgId) {
        orgs.forEach(org => {
            if (org.id === orgId) {
                org.expanded = !org.expanded;

            } else if (org.children.length > 0) {
                this.changeExpanded(org.children, orgId);
            }
        });
        return orgs;
    }

    openAndClose(orgId) {
        this.setState(state => {
            return {
                ...state,
                orgs: this.changeExpanded(state.orgs, orgId)
            }
        });
    }

    componentDidUpdate(prevProps) {
        const { rootOrganizations } = this.props;
        if (!_isEqual(prevProps.rootOrganizations, rootOrganizations)) {
            this.setState({
                orgs: this.parseOrgs(rootOrganizations, [])
            })
        }
    }

    buildUrl(prefix, organizationPath) {
        const { pathname, search, hash } = this.props.location;
        const splittedPath = pathname.slice(1).split('/');

        const newPath = [
            prefix,
            stringify(organizationPath),
            ...splittedPath.slice(2),
        ].join('/');

        return `/${newPath}${search}${hash}`;
    }

    parseOrgs(organizations, carry = []) {
        return organizations.map(org => {
            const itemPath = [...carry, org.id];
            org.path = itemPath;
            org.url = this.buildUrl('organizations', itemPath);
            if (org.children.length > 0) {
                org.children = this.parseOrgs(org.children, itemPath);
            }
            return org;
        });
    }



    getBookmarkOrg(id, orgs) {
        const flattenItems = (items, key) => {
            return items.reduce((flattenedItems, item) => {
                flattenedItems.push(item)
                if (Array.isArray(item[key])) {
                    flattenedItems = flattenedItems.concat(flattenItems(item[key], key))
                }
                return flattenedItems
            }, [])
        }

        return _find(flattenItems(orgs, 'children'), ['id', id]);
    }

    getBookmarkList() {
        const bookmarks = this.props.bookmarks;
        return <List dense={true} component="nav" className="saved" >
                    {bookmarks.length ? bookmarks.map(bookmark => {
                        const org = this.getBookmarkOrg(bookmark.id, this.state.orgs);
                        const isSelected = _isEqual(org.path, this.props.currentPath);

                        return !!org ? <ListItem key={org.id} className={`OrgListItem first-level ${isSelected ? 'selected' : ''}`}>
                            <div className="itemContent">
                                <Link to={org.url || ''}>{org.name}</Link>
                                <StarIcon className="star" onClick={() => this.props.handleUnbookmark(org)} />
                            </div>
                        </ListItem>
                            :
                            <React.Fragment key={bookmark.id} ></React.Fragment>;
                    }) : <ListItem className="OrgListItem empty-state">No starred locations</ListItem>}
                </List>
    }

    getListItems(organizations, depth) {
        if (!organizations.length) return <ListItem className="OrgListItem empty-state">No locations</ListItem>

        return organizations.map(org => {
            const { currentPath, bookmarks, handleBookmark, handleUnbookmark } = this.props;
            const isSelected = _isEqual(org.path, currentPath);
            const opened = org.expanded || !org.children.length;
            const isBookmarked = bookmarks?.length && bookmarks?.findIndex((bookmark) => bookmark.id === org.id) !== -1;
            const paddingLeft = org.leaf && !depth ? '32px' : `${depth * 24 + 8}px`

            return <React.Fragment key={org.path}>
                <ListItem
                    style={{ paddingLeft }}
                    className={`OrgListItem ${!depth ? 'first-level' : ''} ${isSelected ? 'selected' : ''}`}
                >
                    <div className="itemContent">
                        <div className="left">
                            {!org.leaf
                                ? (opened
                                    ? <ArrowDropDownIcon className='icon' onClick={() => this.openAndClose(org.id)} />
                                    : <ArrowRightIcon className='icon' onClick={() => this.openAndClose(org.id)} />)
                                : <div />
                            }
                            <Link to={org.url} className={`${opened ? 'opened' : ''}`}>
                                {org.name}
                            </Link>
                            {isSelected && <CheckIcon fontSize='small' className="check" />}
                        </div>
                        {isBookmarked
                            ? <StarIcon className="star"
                                onClick={() => handleUnbookmark(org)} />
                            : <StarBorderIcon className="star"
                                onClick={() => handleBookmark(org)} />
                        }
                    </div>
                </ListItem>
                {org.children.length > 0 &&
                    <Collapse in={opened} timeout="auto" unmountOnExit>
                        <List className="child-list-container">
                            {this.getListItems(org.children, depth + 1)}
                        </List>
                    </Collapse>
                }
            </React.Fragment>;
        })
    }

    getList(organizations) {
        const { isLoading, currentPath, errorSwitchingOrganization, showBookmarks = false } = this.props;

        return (isLoading || currentPath.length === 0) && !errorSwitchingOrganization ? (
            <React.Fragment >
                <List className="loading-list">
                    <li />
                    <li />
                    <li />
                    <li />
                    <hr className="orgDivider" />
                </List>
                <List>
                    <li />
                    <li />
                    <li />
                    <li />
                </List>
            </React.Fragment>
        ) : <React.Fragment >
                {showBookmarks ? this.getBookmarkList() :
                    <List dense={true} component="nav" className="saved">
                        {this.getListItems(organizations, 0)}
                    </List>
                }
            </React.Fragment>
    }

    render() {
        const { orgs } = this.state;
        return (
            <div className="OrganizationTree">
                {this.getList(orgs)}
            </div>
        );

    }

}

const OrganizationType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    leaf: PropTypes.bool.isRequired,
    children: PropTypes.array.isRequired,
});

const BookmarkType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

OrganizationTree.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    errorLoading: PropTypes.string,
    isSwitchingOrganization: PropTypes.bool.isRequired,
    errorSwitchingOrganization: PropTypes.string,
    rootOrganizations: PropTypes.arrayOf(OrganizationType).isRequired,
    currentPath: PropTypes.arrayOf(PropTypes.number).isRequired,
    bookmarks: PropTypes.arrayOf(BookmarkType),
    handleBookmark: PropTypes.func,
    handleUnbookmark: PropTypes.func,
    orgNameFilter: PropTypes.string,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string
    }),
    showBookmarks: PropTypes.bool
};

export default withRouter(OrganizationTree);
