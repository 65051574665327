import {connect} from "react-redux";

import Organizations from "./Organizations";
import OrganizationsEntity from "../../redux/modules/entities/organizations/Organization";
import { getUserInfo } from '../../redux/modules/session/actions';
import { isFetchingUserInfo } from '../../redux/modules/session/selectors';

const mapStateToProps = (state) => ({
    isLoading: isFetchingUserInfo(state),
    errorLoading: OrganizationsEntity.errorFetchingRootOrganizations(state),
    hasOrganizations: OrganizationsEntity.hasOrganizations(state),
    firstRootOrgId: OrganizationsEntity.getFirstRootOrganizationId(state)
});

const mapDispatchToProps = (dispatch) => ({
    getUserInfo() {
        dispatch(getUserInfo());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);