import 'react-app-polyfill/ie11'; // For IE 11 support
import * as Sentry from "@sentry/react";

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { unregister } from './registerServiceWorker';

import "./index.scss";
import "./everpass-styles.scss";
import App from './components/App';
import store from "./redux/store";
import { everpass_theme, old_theme } from "./muiThemes";
import ErrorBoundary from './components/ErrorBoundary';
import packageInfo from '../package.json';
const { name, version } = packageInfo;

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    normalizeDepth: 8,
    enabled: !!+process.env.REACT_APP_SENTRY_ENABLED,
    environment: process.env.NODE_ENV,
    release: name + '@' + version,
});

function Root() {
    const [currentTheme, setCurrentTheme] = useState(old_theme);

    const setTheme = (type) => {
        setCurrentTheme(type === 'everpass' ? everpass_theme : old_theme);
    };

    return (
        <ThemeProvider theme={currentTheme}>
            <ErrorBoundary>
                <ReduxProvider store={store}>
                    <App setTheme={setTheme} />
                </ReduxProvider>
            </ErrorBoundary>
        </ThemeProvider>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

unregister();
