import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { AuthContext } from '@upshow/auth';

import { bookmarkOrg, closeOrgTreeModal, openOrgTreeModal } from '../../redux/modules/session/actions';
import { getUserInfo, isCanary, isFetchingUserInfo, isOrganizationTreeModalOpen, canManageDevices } from '../../redux/modules/session/selectors';
import Organization from '../../redux/modules/entities/organizations/Organization';

import TopBar from './TopBar';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

const CREATE_SECURE_LINK = gql`
    mutation createPreviewSecureLink($organizationId: Int! $type: SecureLinkType!) {
        orgMutation(organization_id: $organizationId) {
            create_secure_link(type: $type) {
                status message
                secure_link { id link }
            }
        }
    }
`;

const mapStateToProps = (state) => {
    const userInfo = getUserInfo(state);
    const currentOrganization = Organization.getCurrentOrganization(state);

    return {
        bookmarks: userInfo.bookmarkedOrganizations,
        userId: userInfo.id,
        avatar: userInfo.avatar_url,
        email: userInfo.email,
        username: userInfo.username,
        organizationName: Organization.getCurrentOrganizationName(state),
        organizationId: Organization.getCurrentOrganizationId(state),
        isLoading: isFetchingUserInfo(state),
        errorLoading: Organization.errorFetchingRootOrganizations(state),
        isSwitchingOrganization: Organization.isSwitchingOrganization(state),
        errorSwitchingOrganization: Organization.errorSwitchingOrganization(state),
        rootOrganizations: Organization.getRootOrganizations(state, true),
        currentPath: Organization.getCurrentOrganizationPathIds(state),
        isModalOpen: isOrganizationTreeModalOpen(state),
        isLeaf: currentOrganization?.leaf,
        baseUrl: Organization.getCurrentOrganizationUrl(state),
        isCanary: isCanary(state),
        userHasOrgPermission: Organization.getPermissionAccesor(state),
        orgHasFeature: Organization.getFeatureAccesor(state),
        canManageDevices: canManageDevices(state),
    };
};

function ApolloWrapper (props) {
    const { organizationId, userId } = props;
    const client = useApolloClient();
    const { oktaAuth } = useContext(AuthContext);
    const oktaLogout = () => oktaAuth.signOut();

    function logout () {
        oktaLogout().then(() => {
            client.cache.reset();
        });
        Sentry.configureScope(scope => scope.setUser(null));
    }

    Sentry.setUser({ id: userId });

    const [createPreviewSecureLinkMutation, { error }] = useMutation(CREATE_SECURE_LINK);

    const createPreviewSecureLink = () => {
        return createPreviewSecureLinkMutation({
            variables: { organizationId, type: 'PREVIEW' }
        });
    };

    if (!!error) toast.error('Unexpected error, please refresh and try again');

    return <TopBar {...props} logout={logout} createPreviewSecureLink={createPreviewSecureLink}/>;
}

const mapDispatchToProps = (dispatch) => ({
    bookmarkOrg (orgId, isBookmarked) {
        dispatch(bookmarkOrg(orgId, isBookmarked));
    },
    closeOrgTreeModal () {
        dispatch(closeOrgTreeModal());
    },
    openOrgTreeModal () {
        dispatch(openOrgTreeModal());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApolloWrapper);